import { useDispatch } from 'react-redux';
import { useSnackbar } from '../context/SnackbarContext';
import { useUserAuth } from '../context/UserAuthContext';
import { AuthService } from '../services/constants/endpoints';
import useAxios from './useAxios';
import { resetApplicationState } from '../redux/actions/applicationActions';
import { resetBoundingBoxState } from '../redux/actions/boundingBoxActions';
import { resetComparisonState } from '../redux/actions/comparisonActions';
import { resetExtractionState } from '../redux/actions/extractionActions';
import { resetSubmissionState } from '../redux/actions/submissionActions';
import { resetSystemState } from '../redux/actions/systemActions';
import { resetTimerState } from '../redux/actions/timerActions';

const useLogout = () => {
  const axiosPrivate = useAxios();
  const dispatch = useDispatch();
  const { user, removeUser, logOut } = useUserAuth();
  const { openSnackbar } = useSnackbar();

  const confirmLogout = () => {
    const config = {
      headers: {
        'Refresh-Token': user.refreshToken,
      },
    };
    return axiosPrivate.post(`${AuthService.REVOKE_TOKEN}`, {}, config);
  };

  const resetReduxStore = () => {
    dispatch(resetApplicationState());
    dispatch(resetBoundingBoxState());
    dispatch(resetComparisonState());
    dispatch(resetExtractionState());
    dispatch(resetSubmissionState());
    dispatch(resetSystemState());
    dispatch(resetTimerState());
  };

  const logoutUser = async () => {
    try {
      const response = await confirmLogout();
      if (response.data.status) {
        await logOut();
        resetReduxStore();
        removeUser();
      } else {
        openSnackbar('error', response.data.message);
      }
      return response;
    } catch (error) {
      if (error?.response?.status !== 401) {
        openSnackbar('error', error.response.data.message);
      }
      return '';
    }
  };
  return { logoutUser };
};

export default useLogout;
